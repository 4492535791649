@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body {
  overflow-x: hidden;
  background-color: #eff3f8;
  color: #212F3C;
  font-size: 0.9rem;
  font-family: 'Open Sans', sans-serif;
}

.full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}